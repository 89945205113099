
import { defineComponent } from 'vue';
import { reportProps } from '@/helpers/report-comp-props';
import { downloadExport, requestReportExport } from '@/api/report-builder';
import { alertError } from '@/helpers/alert-error';

export default defineComponent({
  props: {
    ...reportProps,
  },
  setup(props) {
    const key = props.report ? props.report._id : undefined;

    const canExport = props.report?.exporter ? true : false;

    const downloadReport = async () => {
      if (!props.report) {
        alertError(new Error(), 'Could not find report to download.');
        return;
      }

      const reportId = props.report._id;

      const exportReq = await requestReportExport(reportId);
      downloadExport(exportReq.nonce);
    };

    return {
      key,
      canExport,
      downloadReport,
    };
  },
});
